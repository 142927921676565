.App {
  font-family: sans-serif;
  text-align: center;
}
body {
  font-family: sans-serif;
}

#container {
  display: flex;
  cursor: pointer;
  background-color: green;
  clip-path: circle(25px at 25px 25px);
  transition: all 0.5s ease-in-out;
}

#info {
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease-in-out;
}

#content {
  padding: 20px;
}

#container:hover {
  background-color: rgba(0, 0, 255, 0.26);
  clip-path: circle(100%);
}

#container:hover #info {
  opacity: 0;
}
